import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import ReactSVG from 'react-svg'
import { format, parseISO } from 'date-fns'
import classNames from 'classnames'
import Collapse, { Panel } from 'rc-collapse'
import { isEmpty } from 'ramda'
import Table from '../../../../containers/settings/shared/table'
import { DevicePanelBody, DevicePanelHeader } from './device-panel-components'
import RoundIcon from '../../shared/round-icon'
import EditButton from '../../shared/edit-button'
import closeIcon from '../../../../../images/closeModal.svg'
import logoutIcon from '../../../../../images/logout.svg'
import arrowUpDark from '../../../../../images/arrow-up-dark.svg'
import arrowDownDark from '../../../../../images/arrow-down-dark.svg'

import styles from './devices.css'

import DEVICE_FRAGMENT from '../../../../../graphql/fragments/device.gql'

const downloadAvaliable = ['android', 'ios']

export const formatDate = (date) => {
  return format(parseISO(date), 'dd/MM/yyyy')
}

export const NoData = () => {
  return (
    <div className={styles.noData}>
      You haven&apos;t registered any saved devices yet.
    </div>
  )
}

export const RemoveDeviceIcon = ({ onClick }) => {
  return (
    <div className={styles.removeButton} onClick={onClick}>
      <RoundIcon alt="Remove Item" src={closeIcon} />
    </div>
  )
}

RemoveDeviceIcon.propTypes = {
  onClick: PropTypes.func.isRequired
}

export const EditDevicesIcon = ({ isEditable, setIsEditable }) => {
  return (
    <div className={classNames(styles.editDevices, { [styles.hidden]: isEditable })}>
      <EditButton text="Edit" onClick={setIsEditable} />
    </div>
  )
}

EditDevicesIcon.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired
}

export const ArrowUpIcon = () => {
  return (
    <div className={styles.upArrowButton}>
      <RoundIcon alt="Arrow Up" src={arrowUpDark} />
    </div>
  )
}

export const ArrowDownIcon = () => {
  return (
    <div className={styles.downArrowButton}>
      <RoundIcon alt="Arrow Down" src={arrowDownDark} />
    </div>
  )
}

export const SignOutDeviceIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <ReactSVG
        path={logoutIcon}
        wrapperClassName={styles.svgWrapper}
        svgClassName={styles.signoutDeviceButton}
      />
    </div>
  )
}

SignOutDeviceIcon.propTypes = {
  onClick: PropTypes.func.isRequired
}

export const getTableColumns = ({ handleDeviceRemoval, handleViewDownloads }) => {
  return [
    {
      Header: 'Device Name',
      id: 'name',
      accessor: 'name',
      Cell: d => (<span className={`${styles.rowInfo} ${styles.deviceName}`}>{d.value}</span>),
      minWidth: 25
    },
    {
      Header: 'Registered',
      id: 'firstSeen',
      accessor: 'firstSeen',
      Cell: d => (<span className={styles.rowInfo}>{formatDate(d.value)}</span>),
      minWidth: 25
    },
    {
      Header: 'Last Used',
      id: 'lastSeen',
      accessor: 'lastSeen',
      Cell: d => (<span className={styles.rowInfo}>{formatDate(d.value)}</span>),
      minWidth: 25
    },
    {
      Header: 'Downloads',
      id: 'numberOfDownloads',
      accessor: 'numberOfDownloads',
      Cell: d => (
        !isEmpty(d.original.platform) &&
        downloadAvaliable.indexOf(d.original.platform.toLowerCase()) !== -1
          ? (
            <span className={styles.rowInfo}>
              (
              {d.value}
              )
              {' '}
              <span
                className={classNames({
                  [styles.deviceDownloads]: true,
                  [styles.clickable]: +d.value > 0
                })}
                onClick={() => {
                  return +d.value > 0 && handleViewDownloads(d.original.uuid)
                }}
              >
                view
              </span>
            </span>
          )
          : (
            <span className={styles.rowInfo}>Not available</span>
          )
      ),
      minWidth: 25
    },
    {
      Header: 'Remove',
      id: 'remove',
      Cell: d => (
        <span className={styles.rowInfo}>
          <RemoveDeviceIcon
            onClick={() => {
              handleDeviceRemoval(d.original.uuid)
            }}
          />
        </span>
      ),
      minWidth: 25
    }
    // TODO: LBXW-592 Sign Out of Device(s)
    /*
    {
      Header: 'Signout',
      id: 'signout',
      Cell: d => (
        <span className={styles.rowInfo}>
          <SignOutDeviceIcon
            onClick={() => { console.info('sign out device: ', d.original.uuid); }}
          />
        </span>
      ),
      minWidth: 20,
    },
    */
  ]
}

export const DesktopTable = ({
  className,
  devices,
  tableColumns,
  handleTableProps,
  handleTableDataProps,
  handleTableHeadProps,
  handleTableRowGroupProps,
  handleTableHeadThProps
}) => {
  return (
    <Table
      className={classNames(className)}
      data={devices}
      columns={tableColumns}
      showPagination={false}
      getTheadThProps={handleTableHeadThProps}
      getTheadProps={handleTableHeadProps}
      getTrGroupProps={handleTableRowGroupProps}
      getTableProps={handleTableProps}
      getTdProps={handleTableDataProps}
      NoDataComponent={NoData}
    />
  )
}

DesktopTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.arrayOf(propType(DEVICE_FRAGMENT)).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  handleTableHeadThProps: PropTypes.func.isRequired,
  handleTableHeadProps: PropTypes.func.isRequired,
  handleTableRowGroupProps: PropTypes.func.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTableDataProps: PropTypes.func.isRequired
}

DesktopTable.defaultProps = {
  className: ''
}

export const MobileTable = ({
  devices,
  tableColumns,
  setActivePanel,
  activePanel
}) => {
  return (
    <Collapse
      accordion
      onChange={setActivePanel}
    >
      {
        devices.map(
          props => (
            <Panel
              // eslint-disable-next-line react/prop-types
              key={props.uuid}
              showArrow={false}
              className={
                classNames(
                  styles.deviceCollapseItem,
                  // eslint-disable-next-line react/prop-types
                  { [styles.itemActive]: activePanel === props.uuid }
                )
              }
              header={(
                <DevicePanelHeader
                  // eslint-disable-next-line react/prop-types
                  deviceName={props.name}
                  // eslint-disable-next-line react/prop-types
                  isOpen={activePanel === props.uuid}
                />
              )}
            >
              <DevicePanelBody deviceProps={props} columns={tableColumns} />
            </Panel>
          )
        )
      }
    </Collapse>
  )
}

MobileTable.propTypes = {
  devices: PropTypes.arrayOf(propType(DEVICE_FRAGMENT)),
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  activePanel: PropTypes.string,
  setActivePanel: PropTypes.func.isRequired
}

MobileTable.defaultProps = {
  devices: [],
  activePanel: ''
}
