
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DownloadFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Download"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentItem"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Episode"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"episodeNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"seasonNumber"},"arguments":[],"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"downloadedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expired"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deletedAt"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":197}};
    doc.loc.source = {"body":"fragment DownloadFields on Download {\n  id\n  contentItem {\n    id\n    title\n    ... on Episode {\n      episodeNumber\n      seasonNumber\n    }\n  }\n  downloadedAt\n  expiresAt\n  expired\n  deletedAt\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
