import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import { CardWrapper, CardContent } from '../../../shared/card'
import { DeviceDownloadsTable } from './downloads-table-components'

import DOWNLOAD_FRAGMENT from '../../../../../../graphql/fragments/download.gql'
import DEVICE_FRAGMENT from '../../../../../../graphql/fragments/device.gql'

import styles from './view-downloads-table.css'

const ViewDownloadsTable = ({
  selectedDevice,
  ...rest
}) => {
  return (
    <CardWrapper className={styles.viewDownloadsCardWrapper}>
      <CardContent>
        <h4 className={styles.selectedDeviceName}>{selectedDevice.name}</h4>
        <DeviceDownloadsTable {...rest} />
      </CardContent>
    </CardWrapper>
  )
}

ViewDownloadsTable.propTypes = {
  selectedDevice: propType(DEVICE_FRAGMENT).isRequired,
  deviceDownloads: PropTypes.arrayOf(propType(DOWNLOAD_FRAGMENT)).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  handleTableHeadThProps: PropTypes.func.isRequired,
  handleTableHeadProps: PropTypes.func.isRequired,
  handleTableRowGroupProps: PropTypes.func.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTableDataProps: PropTypes.func.isRequired
}

export default ViewDownloadsTable
