import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import styles from './devices.css'

import DEVICE_FRAGMENT from '../../../../../graphql/fragments/device.gql'

import {
  ArrowUpIcon,
  ArrowDownIcon
} from './device-table-components'

export const DevicePanelHeader = ({ deviceName, isOpen }) => {
  return (
    <div className={styles.accordionHeader}>
      <div className={styles.deviceName}>{ !isOpen && deviceName}</div>
      { isOpen ? <ArrowUpIcon /> : <ArrowDownIcon /> }
    </div>
  )
}

DevicePanelHeader.propTypes = {
  deviceName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export const DevicePanelBody = ({ columns, deviceProps }) => {
  return (
    <div className={styles.accordionBody}>
      {columns.map((col) => {
        return (
          <DevicePanelRow
            key={col.id}
            columnName={col.Header}
            columnCell={col.Cell({ value: deviceProps[col.id], original: deviceProps })}
          />
        )
      })}
    </div>
  )
}

DevicePanelBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  deviceProps: propType(DEVICE_FRAGMENT).isRequired
}

export const DevicePanelRow = ({ columnName, columnCell }) => {
  return (
    <div className={styles.accordionGroup}>
      <div className={styles.columnName}>{columnName}</div>
      {columnCell}
    </div>
  )
}

DevicePanelRow.propTypes = {
  columnName: PropTypes.string.isRequired,
  columnCell: PropTypes.element.isRequired
}
