import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { format, parseISO } from 'date-fns'
import Table from '../../../../../containers/settings/shared/table'

import styles from './view-downloads-table.css'

import DOWNLOAD_FRAGMENT from '../../../../../../graphql/fragments/download.gql'

export const formatDate = date => {
  return format(parseISO(date), 'dd/MM/yyyy')
}

export const NoData = () => {
  return (
    <div className={styles.noData}>
      You haven&apos;t downloaded anything yet.
    </div>
  )
}

export const getDownloadsTableColumns = () => {
  return [
    {
      Header: 'Title',
      id: 'title',
      accessor: 'title',
      Cell: d => {
        return (
          <div className={styles.noTextOverflow}>
            <span>{d.value}</span>
          </div>
        )
      },
      minWidth: 40
    },
    {
      Header: 'Description',
      id: 'description',
      accessor: 'description',
      Cell: d => {
        return (
          <div className={styles.noTextOverflow}>
            <span>{d.value}</span>
          </div>
        )
      },
      minWidth: 25
    },
    {
      Header: 'Downloaded',
      id: 'downloadedAt',
      accessor: 'downloadedAt',
      Cell: d => <span className={styles.rowInfo}>{formatDate(d.value)}</span>,
      minWidth: 25
    }
  ]
}

export const DeviceDownloadsTable = ({
  tableColumns,
  deviceDownloads,
  handleTableProps,
  handleTableDataProps,
  handleTableHeadProps,
  handleTableRowGroupProps,
  handleTableHeadThProps
}) => {
  return (
    <Table
      className={styles.downloadsTable}
      data={deviceDownloads}
      columns={tableColumns}
      showPagination={false}
      getTheadThProps={handleTableHeadThProps}
      getTheadProps={handleTableHeadProps}
      getTrGroupProps={handleTableRowGroupProps}
      getTableProps={handleTableProps}
      getTdProps={handleTableDataProps}
      defaultPageSize={50}
      NoDataComponent={NoData}
    />
  )
}

DeviceDownloadsTable.propTypes = {
  deviceDownloads: PropTypes.arrayOf(propType(DOWNLOAD_FRAGMENT)).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func.isRequired,
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string,
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.number
    })
  ).isRequired,
  handleTableHeadThProps: PropTypes.func.isRequired,
  handleTableHeadProps: PropTypes.func.isRequired,
  handleTableRowGroupProps: PropTypes.func.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTableDataProps: PropTypes.func.isRequired
}
