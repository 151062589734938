import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import Modal from '../../../../modal'

import ViewDownloadsTableContainer from '../../../../../containers/settings/my-account/devices-and-downloads/view-downloads-table'

import DEVICE_FRAGMENT from '../../../../../../graphql/fragments/device.gql'
import DOWNLOAD_FRAGMENT from '../../../../../../graphql/fragments/download.gql'

import styles from './view-downloads-modal.css'

const CloseIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0 L24 24z" />
    <path d="M24 0 L0 24z" />
  </svg>
)

CloseIcon.propTypes = {
  className: PropTypes.string.isRequired
}

const ViewDownloadsModal = ({
  onModalClose,
  ...rest
}) => {
  return (
    <Modal
      isNotificationModal
      onCustomClose={onModalClose}
      shouldCloseOnOverlayClick
    >
      <div className={styles.headline}>
        <button className={styles.modalCloseIcon} onClick={onModalClose}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.viewDownloadsModalWrapper}>
        <h2 className={styles.header}>Downloads</h2>
        <ViewDownloadsTableContainer {...rest} />
      </div>
    </Modal>
  )
}

ViewDownloadsModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  selectedDevice: propType(DEVICE_FRAGMENT).isRequired,
  deviceDownloads: PropTypes.arrayOf(propType(DOWNLOAD_FRAGMENT)).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  handleTableHeadThProps: PropTypes.func.isRequired,
  handleTableHeadProps: PropTypes.func.isRequired,
  handleTableRowGroupProps: PropTypes.func.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTableDataProps: PropTypes.func.isRequired
}

export default ViewDownloadsModal
