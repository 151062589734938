import {
  compose, withStateHandlers, withProps, onlyUpdateForPropTypes
} from 'recompose'
import MySavedDevicesCard from '../../../../components/settings/my-account/devices-and-downloads/my-saved-devices-card'

const initialCardState = () => ({
  isEditable: false
})

const enhance = compose(
  withStateHandlers(
    initialCardState,
    {
      setIsEditable: ({ isEditable }) => () => {
        console.info('will change isEditable here: ', isEditable)
        return isEditable
        // TODO: Edit device name covered under LBXW-593
        // return {
        //   isEditable: !isEditable,
        // };
      }
    }
  ),
  withProps(({ devices }) => {
    return {
      hasNoDevices: devices.length === 0
    }
  }),
  onlyUpdateForPropTypes
)

export default enhance(MySavedDevicesCard)
