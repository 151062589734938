import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import styles from './devices.css'

import { DesktopTable, MobileTable } from './device-table-components'

import DEVICE_FRAGMENT from '../../../../../graphql/fragments/device.gql'

import ConfirmationModal from '../../../shared/confirmation-modal'

import ViewDownloadsModal from './view-downloads-modal'

const MySavedDevicesTable = ({
  devices,
  tableColumns,
  activePanel,
  matches,
  setActivePanel,
  isConfirmationModalOpen,
  isViewDownloadsModalOpen,
  selectedDeviceUuid,
  handleCancelClick,
  handleConfirmClick,
  handleCloseModalClick,
  ...rest
}) => {
  return (
    <div>
      {
        matches ? (
          // Desktop view
          <DesktopTable
            devices={devices}
            className={styles.devicesAndDownloadsTable}
            tableColumns={tableColumns}
            {...rest}
          />
        ) : (
          // Mobile view
          <MobileTable
            devices={devices}
            tableColumns={tableColumns}
            setActivePanel={setActivePanel}
            activePanel={activePanel}
          />
        )
      }
      {
        isConfirmationModalOpen && (
          <ConfirmationModal
            header="Are you sure?"
            message={
              `You're about to permanently delete this device,
            are you sure this is what you'd like to do?`
            }
            confirmText="Yes, Delete this device"
            cancelText="Cancel"
            onCancelClick={handleCancelClick}
            onConfirmClick={handleConfirmClick}
          />
        )
      }
      {
        isViewDownloadsModalOpen && (
          <ViewDownloadsModal
            onModalClose={handleCloseModalClick}
            devices={devices}
            selectedDeviceUuid={selectedDeviceUuid}
          />
        )
      }
    </div>
  )
}

MySavedDevicesTable.propTypes = {
  devices: PropTypes.arrayOf(propType(DEVICE_FRAGMENT)).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string,
    id: PropTypes.string.isRequired,
    minWidth: PropTypes.number
  })).isRequired,
  activePanel: PropTypes.string,
  matches: PropTypes.bool.isRequired,
  setActivePanel: PropTypes.func.isRequired,
  handleTableProps: PropTypes.func.isRequired,
  handleTableHeadProps: PropTypes.func.isRequired,
  handleTableRowGroupProps: PropTypes.func.isRequired,
  handleTableDataProps: PropTypes.func.isRequired,
  handleTableHeadThProps: PropTypes.func.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  isViewDownloadsModalOpen: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCloseModalClick: PropTypes.func.isRequired,
  selectedDeviceUuid: PropTypes.string.isRequired
}

MySavedDevicesTable.defaultProps = {
  activePanel: null
}

export default MySavedDevicesTable
