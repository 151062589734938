import {
  compose,
  lifecycle,
  withStateHandlers,
  withProps,
  withHandlers,
  branch,
  renderComponent
} from 'recompose'
import classNames from 'classnames'

import { getTableColumns, NoData } from '../../../../components/settings/my-account/devices-and-downloads/device-table-components'
import MySavedDevicesTable from '../../../../components/settings/my-account/devices-and-downloads/my-saved-devices-table'
import withDeleteDeviceMutation from '../../../../hoc/with-delete-device-mutation'

import styles from '../../../../components/settings/my-account/devices-and-downloads/devices.css'

const initialTableState = () => ({
  activePanel: null,
  isConfirmationModalOpen: false,
  selectedDeviceUuid: '',
  isViewDownloadsModalOpen: false
})

const enhance = compose(
  withStateHandlers(
    initialTableState,
    {
      setActivePanel: () => (deviceUuid) => {
        return {
          activePanel: deviceUuid
        }
      },
      setDisplayConfirmationModal: () => (deviceUuid) => {
        return {
          isConfirmationModalOpen: Boolean(deviceUuid),
          selectedDeviceUuid: deviceUuid || ''
        }
      },
      setDisplayViewDownloadsModal: () => (deviceUuid) => {
        return {
          isViewDownloadsModalOpen: Boolean(deviceUuid),
          selectedDeviceUuid: deviceUuid || ''
        }
      }
    }
  ),
  withDeleteDeviceMutation,
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      // Only reset the currently active panel whenever
      // the window is resized with a panel open
      if (
        (this.props.matches !== nextProps.matches) &&
        (!nextProps.matches && this.props.activePanel)
      ) {
        this.props.setActivePanel(null)
      }
    }
  }),
  branch(
    ({ hasNoDevices }) => hasNoDevices,
    renderComponent(NoData)
  ),
  withHandlers({
    // Handling dynamic styling for react-table
    handleTableHeadProps: () => () => {
      return {
        className: classNames(styles.deviceTableHead, styles.header)
      }
    },
    handleTableRowGroupProps: () => () => {
      return {
        className: classNames(styles.deviceTableRowGroup, styles.row)
      }
    },
    handleTableHeadThProps: () => (state, rowInfo, column) => {
      const isDeviceNameCol = column.id === 'name'
      const isSignoutDeviceCol = column.id === 'signout'
      return {
        className: classNames(
          styles.deviceTableHeadTh,
          { [styles.thFirst]: isDeviceNameCol },
          { [styles.thLast]: isSignoutDeviceCol }
        )
      }
    },
    handleTableDataProps: () => (state, rowInfo, column) => {
      const isDeviceNameCol = column.id === 'name'
      const isSignoutDeviceCol = column.id === 'signout'
      return {
        className: classNames(
          styles.deviceTableData,
          { [styles.tdFirst]: isDeviceNameCol },
          { [styles.tdLast]: isSignoutDeviceCol }
        )
      }
    },
    handleTableProps: () => (state, rowInfo, column, instance) => {
      return {
        style: {
          display: instance.props.data.length === 0 && 'none'
        }
      }
    },
    // Modal handlers
    handleCancelClick: ({ setDisplayConfirmationModal }) => () => {
      setDisplayConfirmationModal(false)
    },
    handleConfirmClick: ({
      setDisplayConfirmationModal,
      deleteDevice,
      selectedDeviceUuid
    }) => () => {
      deleteDevice(selectedDeviceUuid)
        .then(() => {
          setDisplayConfirmationModal(false)
        })
    },
    handleDeviceRemoval: ({
      setDisplayConfirmationModal
    }) => (deviceUuid) => {
      setDisplayConfirmationModal(deviceUuid)
    },
    handleViewDownloads: ({
      setDisplayViewDownloadsModal
    }) => (deviceUuid) => {
      setDisplayViewDownloadsModal(deviceUuid)
    },
    handleCloseModalClick: ({
      setDisplayViewDownloadsModal
    }) => () => {
      setDisplayViewDownloadsModal(false)
    }
  }),
  withProps(({ handleDeviceRemoval, handleViewDownloads }) => {
    const tableColumns = getTableColumns({ handleDeviceRemoval, handleViewDownloads })
    return { tableColumns }
  })
)

export default enhance(MySavedDevicesTable)
