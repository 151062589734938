import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import Media from 'react-media'
import { CardWrapper, CardContent, CardInnerHeading } from '../../shared/card'
import MySavedDevicesTableContainer from '../../../../containers/settings/my-account/devices-and-downloads/my-saved-devices-table'
import { SCREEN_SIZE_BREAKPOINTS } from '../../../../constants'

import styles from './devices.css'

import DEVICE_FRAGMENT from '../../../../../graphql/fragments/device.gql'

const MySavedDevicesCard = ({
  devices,
  isEditable,
  setIsEditable,
  hasNoDevices
}) => {
  return (
    <CardWrapper>
      <CardContent className={styles.cardContent}>
        {
          !hasNoDevices && (
            <CardInnerHeading
              className={styles.cardInnerHeading}
              header="My Saved Devices"
            />
          )
        }
        <Media query={`(min-width: ${SCREEN_SIZE_BREAKPOINTS.MIN_480}px)`}>
          {matches => (
            <MySavedDevicesTableContainer
              matches={matches}
              devices={devices}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              hasNoDevices={hasNoDevices}
            />
          )}
        </Media>
      </CardContent>
    </CardWrapper>
  )
}

MySavedDevicesCard.propTypes = {
  devices: PropTypes.arrayOf(propType(DEVICE_FRAGMENT)).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  hasNoDevices: PropTypes.bool.isRequired
}

export default MySavedDevicesCard
