import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import Section from '../../shared/section'
import MySavedDevicesCardContainer from '../../../../containers/settings/my-account/devices-and-downloads/my-saved-devices-card'

import DEVICE_FRAGMENT from '../../../../../graphql/fragments/device.gql'
import { TESTERS } from '../../../../lib/tester-classnames'

function Devices({
  devices
}) {
  return (
    <div>
      <Section title="Devices and Downloads" testerClassName={TESTERS.ACCOUNT.DEVICES_DOWNLOADS}>
        <MySavedDevicesCardContainer
          devices={devices}
        />
      </Section>
    </div>
  )
}

Devices.propTypes = {
  devices: PropTypes.arrayOf(propType(DEVICE_FRAGMENT)).isRequired
}

export default Devices
