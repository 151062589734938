import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { reject, pathOr } from 'ramda'
import withMutateAndLoading from './with-mutate-and-loading'
import { displayErrorDialog } from '../actions'
import { getGQLErrorMsg } from '../lib/apollo'

import DEVICES_QUERY from '../../graphql/queries/devices.gql'
import DELETE_DEVICE_MUTATION from '../../graphql/mutations/delete-device.gql'
import { segmentTrackDeleteDevice } from '../segment/segment-track'

const withDeleteDeviceMutation = compose(
  connect(),
  graphql(DELETE_DEVICE_MUTATION),
  withMutateAndLoading,
  withHandlers({
    deleteDevice: ({ mutateAndLoading, dispatch }) => (deviceUuid) => {
      return mutateAndLoading('deviceDelete', {
        variables: {
          id: deviceUuid
        },
        update: (proxy, result) => {
          const data = proxy.readQuery({ query: DEVICES_QUERY })
          const currentDevices = data.devices
          const deviceDeletedSuccessfully = pathOr(false, ['data', 'deleteDevice'], result)

          if (deviceDeletedSuccessfully) {
            const devices = reject(
              n => n.uuid === deviceUuid,
              currentDevices
            )
            proxy.writeQuery({
              query: DEVICES_QUERY,
              data: { devices }
            })

            // Add segment data analytics for deleting device
            const deletedDevice = currentDevices.find(device => device.uuid === deviceUuid)
            const {
              uuid, platform, os, name, model
            } = deletedDevice
            segmentTrackDeleteDevice({
              deviceId: uuid,
              platform,
              os,
              name,
              model
            })
          }
        }
      }).catch((err) => {
        dispatch(displayErrorDialog({
          message: getGQLErrorMsg(err)
        }))
      })
    }
  })
)

export default withDeleteDeviceMutation
