import {
  compose, withProps, withHandlers, branch, renderComponent
} from 'recompose'
import { graphql } from 'react-apollo'
import classNames from 'classnames'
import {
  find,
  propEq,
  pathOr,
  pipe,
  sort,
  lensPath,
  lensProp,
  reduce
} from 'ramda'
import { viewOr } from '../../../../../lib/fp'

import { LoadingModal } from '../../../../../components/loading/modal'
import { getDownloadsTableColumns } from '../../../../../components/settings/my-account/devices-and-downloads/view-downloads-table/downloads-table-components'
import ViewDownloadsTable from '../../../../../components/settings/my-account/devices-and-downloads/view-downloads-table'

import DOWNLOADS_QUERY from '../../../../../../graphql/queries/downloads.gql'

import styles from '../../../../../components/settings/my-account/devices-and-downloads/view-downloads-table/view-downloads-table.css'

const byMostRecent = (a, b) => (
  new Date(b.downloadedAt).getTime() - new Date(a.downloadedAt).getTime()
)
const sortDownloads = sort(byMostRecent)

// Data props for tables
const titleLens = lensPath(['contentItem', 'title'])
const downloadedAtLens = lensProp('downloadedAt')
const getDescription = ({ contentItem: { episodeNumber, seasonNumber, __typename } = {} }) => {
  return __typename === 'Title' ? 'Movie' : `TV / S${seasonNumber} / E${episodeNumber}`
}

const extractDownloadsTableDataObject = (obj) => {
  return {
    title: viewOr('', titleLens, obj),
    description: getDescription(obj),
    downloadedAt: viewOr('', downloadedAtLens, obj)
  }
}

const transformObj = (acc, obj) => {
  return acc.concat(extractDownloadsTableDataObject(obj))
}

/**
 * Gets the table data from the downloadsQuery.
 * In combination with the lenses produces a new list
 * that looks like: [{ title, description, downloadedAt }, ...],
 * and is sorted by most recent download
 */
const getTableData = pipe(
  pathOr([], ['downloads', 'items']),
  reduce(transformObj, []),
  sortDownloads
)

const enhance = compose(
  graphql(DOWNLOADS_QUERY, {
    name: 'downloadsQuery',
    options: ({ selectedDeviceUuid }) => {
      return {
        variables: {
          input: {
            deviceId: selectedDeviceUuid
          }
        }
      }
    }
  }),
  branch(
    ({ downloadsQuery }) => pathOr(false, ['loading'], downloadsQuery) || pathOr(false, ['error'], downloadsQuery),
    renderComponent(LoadingModal)
  ),
  withHandlers({
    // Handling dynamic styling for react-table
    handleTableHeadProps: () => () => {
      return {
        className: classNames(styles.downloadsTableHead, styles.header)
      }
    },
    handleTableRowGroupProps: () => () => {
      return {
        className: classNames(styles.downloadsTableRowGroup, styles.row)
      }
    },
    handleTableHeadThProps: () => (state, rowInfo, column) => {
      const isContentTitle = column.id === 'title'
      const isContentDownloadedAt = column.id === 'downloadedAt'
      return {
        className: classNames(
          styles.downloadsTableHeadTh,
          { [styles.thFirst]: isContentTitle },
          { [styles.thLast]: isContentDownloadedAt }
        )
      }
    },
    handleTableDataProps: () => (state, rowInfo, column) => {
      const isContentTitle = column.id === 'title'
      const isContentDownloadedAt = column.id === 'downloadedAt'
      return {
        className: classNames(
          styles.downloadsTableData,
          { [styles.tdFirst]: isContentTitle },
          { [styles.tdLast]: isContentDownloadedAt }
        )
      }
    },
    handleTableProps: () => (state, rowInfo, column, instance) => {
      return {
        style: {
          display: instance.props.data.length === 0 && 'none'
        }
      }
    }
  }),
  withProps(({ devices, selectedDeviceUuid, downloadsQuery }) => {
    const selectedDevice = find(propEq('uuid', selectedDeviceUuid))(devices)
    const tableColumns = getDownloadsTableColumns()
    const deviceDownloads = getTableData(downloadsQuery)

    return {
      selectedDevice,
      tableColumns,
      deviceDownloads
    }
  })
)

export default enhance(ViewDownloadsTable)
